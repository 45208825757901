/* DiscordButton.css */
.discord-button-wrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000; /* Ensures it stays on top */
}

.discord-button {
    background-color: #7289DA;
    color: white;
    border-radius: 50%;
    padding: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.discord-button:hover {
    transform: scale(1.1);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

/* Tooltip styles */
.tooltip {
    visibility: hidden;
    opacity: 0;
    background-color: #333;
    color: white;
    text-align: center;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    position: absolute;
    bottom: 70px; /* Adjusts the distance above the button */
    transition: opacity 0.3s ease, visibility 0.3s ease;
    white-space: nowrap;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Show tooltip on hover */
.discord-button-wrapper:hover .tooltip {
    visibility: visible;
    opacity: 1;
}
