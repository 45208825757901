/* Panel Wrapper */
.moderator-panel {
  padding: 20px;
  background-color: var(--card-background);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.moderator-panel-title {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--text-color);
  font-weight: bold;
}

/* Section Header Buttons */
.moderator-panel-header {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.moderator-panel-header button {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  background-color: var(--button-background);
  color: var(--button-text);
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.moderator-panel-header button:hover {
  background-color: var(--hover-background);
}

.moderator-panel-header button.active {
  background-color: var(--hover-background);
  font-weight: bold;
}

/* Table Styling */
.moderator-panel table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--section-background);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.moderator-panel table thead {
  background-color: var(--section-header-bg);
}

.moderator-panel table thead th {
  padding: 10px 15px;
  text-align: left;
  color: var(--text-color-secondary);
  border-bottom: 2px solid var(--border-color);
}

.moderator-panel table tbody tr {
  transition: background-color 0.3s ease;
}

.moderator-panel table tbody tr:hover {
  background-color: var(--hover-background);
}

.moderator-panel table tbody td {
  padding: 10px 15px;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-color);
}

/* Action Buttons in Tables */
.moderator-panel table tbody td .action-buttons {
  display: flex;
  gap: 10px; /* Space between buttons */
  justify-content: flex-start; /* Align to the left */
  align-items: center; /* Center vertically */
}

.moderator-panel table tbody td button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.moderator-panel table tbody td button.ban {
  background-color: var(--error-color);
  color: var(--button-text);
}

.moderator-panel table tbody td button.ban:hover {
  background-color: darkred;
}

.moderator-panel table tbody td button.unban {
  background-color: var(--success-color);
  color: var(--button-text);
}

.moderator-panel table tbody td button.unban:hover {
  background-color: green;
}

.moderator-panel table tbody td button.verify {
  background-color: var(--button-background);
  color: var(--button-text);
}

.moderator-panel table tbody td button.verify:hover {
  background-color: var(--hover-background);
}

/* Reports Section */
.reports-list {
  margin-top: 20px;
}

.report-item {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.report-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: var(--section-header-bg);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.report-summary:hover {
  background-color: var(--hover-background);
}

.report-summary span {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-color);
}

.expand-button {
  background: var(--button-background);
  color: var(--button-text);
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.expand-button:hover {
  background-color: var(--hover-background);
}

/* Expanded Report Details */
.report-details {
  padding: 15px;
  background-color: var(--section-background);
}

.report-details table {
  width: 100%;
  border-collapse: collapse;
}

.report-details th,
.report-details td {
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
}

.report-details th {
  text-align: left;
  background-color: var(--section-header-bg);
  color: var(--text-color-secondary);
}

.report-details tr:last-child td {
  border-bottom: none;
}

/* Utility Classes */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-success {
  color: var(--success-color);
}

.text-error {
  color: var(--error-color);
}

/* Status Indicators */
.status-verified,
.status-not-verified,
.status-banned,
.status-not-banned {
  font-weight: bold;
  font-size: 16px;
}

.status-verified {
  color: var(--success-color); /* Green */
}

.status-not-verified {
  color: var(--error-color); /* Red */
}

.status-banned {
  color: var(--error-color); /* Red */
}

.status-not-banned {
  color: var(--success-color); /* Green */
}

/* Icons */
.status-icon {
  margin: 0; /* Remove side margins */
  font-size: 1.4em;
}

.status-icon.green {
  color: var(--success-color);
}

.status-icon.yellow {
  color: var(--warning-color, #f1c40f);
}

.status-icon.red {
  color: var(--error-color);
}

.status-icon.clear {
  font-size: 16px;
}

/* Button Styling */
.status-button {
  padding: 8px 16px;
  margin-right: 8px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center; /* Center icons inside the button */
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-transform: uppercase;
  color: #fff;
}

.status-button:hover {
  transform: scale(1.05);
}

.status-button.green {
  background-color: var(--success-color, #28a745);
}

.status-button.green:hover {
  background-color: #218838;
}

.status-button.yellow {
  background-color: var(--warning-color, #f1c40f);
  color: #333;
}

.status-button.yellow:hover {
  background-color: #d4ac0d;
}

.status-button.red {
  background-color: var(--error-color, #dc3545);
}

.status-button.red:hover {
  background-color: #c82333;
}

.status-button.clear {
  background-color: var(--clear-color, #6c757d);
  color: white;
}

.status-button.clear:hover {
  background-color: #5a6268;
}

/* Responsive Design */
@media (max-width: 768px) {
  .moderator-panel table {
    font-size: 14px;
  }

  .moderator-panel table tbody td {
    padding: 8px 10px;
  }

  .moderator-panel table thead th {
    padding: 8px 10px;
  }

  .moderator-panel-header button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .report-summary span {
    font-size: 14px;
  }

  .expand-button {
    padding: 6px 10px;
    font-size: 14px;
  }
}
