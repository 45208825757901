/* Light Mode Variables */
:root {
    --background-color: #f9f9f9;
    --text-color: #333;
    --card-background: #ffffff;
    --tab-button-background: #f1f1f1;
    --button-background: #4682B4;
    --button-hover: #3c6f99;
    --input-background: #f0f0f0;
    --input-text: #333;
    --approved-background: #d4edda;
    --approved-border: #28a745;
    --rejected-background: #f8d7da;
    --rejected-border: #dc3545;
    --pending-background: #fff3cd;
    --pending-border: #ffc107;
}

/* Dark Mode Variables */
body.dark {
    --background-color: #1e1e1e;
    --text-color: #e0e0e0;
    --card-background: #2a2a2a;
    --tab-button-background: #3a3a3a;
    --button-background: #444444;
    --button-hover: #555555;
    --input-background: #3a3a3a;
    --input-text: #e0e0e0;
    --approved-background: #3c543c;
    --approved-border: #28a745;
    --rejected-background: #5e3c3c;
    --rejected-border: #dc3545;
    --pending-background: #5e5a3c;
    --pending-border: #ffc107;
}

/* Cheat Requests Container */
.cheatrequests-container {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 20px;
    background-color: var(--card-background);
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    color: var(--text-color);
}

.cheatrequests-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 20px;
    text-align: center;
    animation: fadeIn 1.2s ease-in-out;
}

.steam-link {
    margin-top: 10px;
    font-size: 0.95rem;
    font-weight: bold;
    color: var(--link-color)
}

.steam-link:hover {
    color: var(--link-hover); /* Steam hover color */
    text-decoration: underline;
}

.steam-link a {
    color: var(--link-color); /* Steam's branding color */
    text-decoration: none;
}

.steam-link a:hover {
    color: var(--link-hover); /* Steam hover color */
    text-decoration: underline;
}

/* Tab Buttons */
.tab-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
}

.tab-button {
    padding: 6px 12px;
    background-color: var(--tab-button-background);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin: 5px;
    flex: 1 1 45%;
    color: var(--text-color);
}

.tab-button.active {
    background-color: var(--button-background);
    color: white;
}

.tab-button:hover {
    background-color: var(--button-hover);
    color: white;
}

.cheatrequests-toggle-button {
    background-color: var(--button-background);
    color: white;
    border: none;
    padding: 10px 12px;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    margin: 10px 0;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.cheatrequests-toggle-button:hover {
    background-color: var(--button-hover);
    transform: scale(1.05);
}

/* Input Container */
.cheatrequests-input-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the inputs horizontally */
    justify-content: center; /* Center the inputs vertically */
    margin-bottom: 20px;
    width: 100%;
}


.cheatrequests-input {
    padding: 12px;
    width: 100%;
    max-width: 500px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: none;
    background-color: var(--input-background);
    color: var(--input-text);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
}

.cheatrequests-input:focus {
    outline: none;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.1);
    background-color: var(--tab-button-background);
}

/* Submit Button */
.cheatrequests-button {
    padding: 12px 24px;
    font-size: 1.2rem;
    color: #fff;
    background-color: var(--button-background);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 100%;
    max-width: 500px;
}

.search-input {
    display: block;
    margin: 20px auto; /* Center horizontally */
    padding: 10px;
    width: 100%;
    max-width: 400px; /* Set a max width for the input */
    border-radius: 5px;
    border: none;
    background-color: var(--input-background);
    color: var(--input-text);
    font-size: 1rem;
    text-align: center; /* Center the input text */
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-input:focus {
    outline: none;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.1);
    background-color: var(--tab-button-background);
}


.cheatrequests-button:hover {
    background-color: var(--button-hover);
    transform: scale(1.05);
}

.cheatrequests-requests {
    margin-top: 40px;
}

/* Card Styles */
.cheatrequests-card {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    color: var(--text-color);
    background-color: var(--card-background);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.cheatrequests-card .game-info {
    flex-grow: 1;
    padding-right: 20px;
}

.cheatrequests-card.approved {
    background-color: var(--approved-background);
    border-left: 5px solid var(--approved-border);
}

.cheatrequests-card.completed {
    background-color: var(--approved-background);
    border-left: 5px solid var(--approved-border);
}

.cheatrequests-card.rejected {
    background-color: var(--rejected-background);
    border-left: 5px solid var(--rejected-border);
}

.cheatrequests-card.pending {
    background-color: var(--pending-background);
    border-left: 5px solid var(--pending-border);
}

/* Vote Button */
.cheatrequests-vote-button {
    padding: 10px 20px;
    background-color: var(--button-background);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.cheatrequests-vote-button:hover {
    background-color: var(--button-hover);
    transform: scale(1.05);
}

/* Error Styling */
.global-error, .cheatrequests-error {
    color: #fff;
    background-color: #f44336;
    font-size: 1.1rem;
    margin-bottom: 20px;
    text-align: center;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    line-height: 1.5;
    animation: bounceIn 0.5s ease-in-out;
    transition: all 0.3s ease;
}

.anonymous-request, a {
   color: var(--link-color);
   font-weight: 700;
}


/* Bounce-in Animation */
@keyframes bounceIn {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    50% {
        opacity: 0.7;
        transform: scale(1.05);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .cheatrequests-container {
        padding: 15px;
    }

    .cheatrequests-title {
        font-size: 2rem;
    }

    .tab-buttons {
        flex-direction: column;
        gap: 10px;
    }

    .tab-button {
        flex: 1 1 100%;
        text-align: center;
        padding: 8px;
    }

    .cheatrequests-card {
        padding: 12px;
        flex-direction: column;
        align-items: flex-start;
    }

    .cheatrequests-vote-button {
        width: 100%;
        margin-top: 10px;
    }

    .global-error, .cheatrequests-error {
        font-size: 1rem;
        padding: 12px;
    }
}
