/* Page Wrapper */
.page-wrapper {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
}

/* Sidebar */
.sidebar {
    width: 250px;
    background: linear-gradient(135deg, #2f1235, #313c52); /* Keep original background */
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sidebar-icon {
    margin-right: 12px;
    font-size: 18px;
    color: white;
    vertical-align: middle;
}


.sidebar h2 {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 20px;
    position: relative; /* Required for positioning the pseudo-element */
    text-align: center; /* Center-align text */
}

.sidebar h2::after {
    content: ""; /* Creates the line */
    display: block;
    width: 150%; /* Adjust the width of the line */
    height: 2px; /* Line thickness */
    background-color: var(--border-color); /* Line color */
    margin: 8px auto 0; /* Centers the line and adds spacing */
}

.sidebar .menu {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.sidebar .menu a {
    color: white; /* Keep original text color */
    text-decoration: none;
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
    text-align: left;
    transition: background 0.3s;
    background-color: transparent;
}

.menu button {
    background: none;
    border: none;
    color: white; /* Keep original text color */
    text-align: left;
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background 0.3s;
}

.menu button:hover,
.menu button.active {
    position: relative; /* Required for pseudo-elements */
    background: linear-gradient(90deg, #0056e0, #007bff, #ffffff, #007bff, #0056e0);
    background-size: 300% 100%; /* Larger size for a smooth effect */
    animation: shiny 10s ease-in-out infinite; /* Much slower animation */
    color: #fff; /* Adjust text color for better contrast */
    overflow: hidden; /* Prevent visual overflow issues */
}


.sidebar .logout-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    color: white; /* Keep original logout button color */
}

/* Main Content */
.main-content {
    flex: 1;
    padding: 100px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--background-color); /* Use palette background */
    color: var(--text-color); /* Use palette text color */
}

/* Card */
.card {
    background-color: var(--card-background);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

/* Profile Section */
.profile-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.profile-header {
    padding-top: 10px;
    margin-top: 0;
    gap: 15px;
}

.profile-link {
    color: var(--text-color);
    text-decoration: none;
    font-weight: bold;
}

.profile-link:hover {
    text-decoration: underline;
}

.avatar-and-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.avatar-container {
    margin-top: 0;
}

.avatar-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    background-color: var(--input-background);
    border: 2px solid var(--border-color);
}

.default-avatar-dashboard {
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    background-color: var(--input-background);
    border: 2px solid var(--border-color);
}

.avatar-upload-input {
    display: block;
    margin: 0 auto;
    padding: 5px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    background-color: var(--input-background);
    color: var(--input-text);
    font-size: 0.9rem;
    cursor: pointer;
}

.avatar-update-button {
    margin-top: 10px;
    padding: 8px 15px;
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
}

.avatar-update-button:hover {
    background-color: var(--hover-background);
    transform: scale(1.05);
}

.profile-info h3 {
    margin: 0 0 10px 0;
}

/* Profile Info Styling */
.profile-info {
    display: flex;
    flex-direction: column; /* Stack child elements vertically */
    gap: 15px; /* Add spacing between the stacked elements */
    align-items: center; /* Center elements horizontally */
}

/* Discord Name Styling */
.profile-info .discord-name {
    margin-top: 15px;
    display: inline-flex;
    align-items: center; /* Ensures vertical alignment of icon and text */
    font-size: 1.2rem;
    background-color: rgba(114, 137, 218, 0.1); /* Light Discord background */
    padding: 10px 20px;
    border-radius: 12px; /* Rounded corners for a clean aesthetic */
    color: #7289da; /* Discord branding color */
    border: 2px solid #7289da; /* Border matching Discord color */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Discord Icon Styling */
.profile-info .discord-name .discord-icon {
    margin-right: 10px; /* Space between icon and text */
    font-size: 1.5rem; /* Icon size slightly larger than text */
    color: #7289da; /* Discord branding color */
    opacity: 0.8; /* Slightly dimmed appearance */
    transition: opacity 0.3s ease; /* Smooth transition on hover */
    display: flex; /* Ensures proper alignment */
    align-items: center; /* Centers the icon within its container */
}

/* Discord Username Styling */
.profile-info .discord-name .discord-username {
    font-size: 1rem; /* Slightly smaller font size for username */
    color: #7289da; /* Match text color to branding */
    font-weight: 500; /* Slightly bold font for emphasis */
    display: inline-block; /* Keeps text aligned with the icon */
    line-height: 1; /* Ensures proper vertical alignment */
}

/* Hover Effect */
.profile-info .discord-name:hover .discord-icon {
    opacity: 1; /* Fully visible icon on hover */
}

.profile-info .discord-name:hover {
    background-color: rgba(114, 137, 218, 0.2); /* Slightly darker background */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
}


/* Email Container Styling */
.profile-info .email-container {
    margin-top: 15px;
    display: inline-flex;
    align-items: center; /* Centers icon and text vertically */
    font-size: 1.2rem;
    background-color: rgba(128, 128, 128, 0.1); /* Light gray background */
    padding: 10px 20px;
    border-radius: 12px; /* Rounded corners */
    color: #808080; /* Gray text color */
    border: 2px solid #808080; /* Gray border */
    transition: all 0.3s ease; /* Smooth hover transition */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Email Icon Styling */
.profile-info .email-container .email-icon {
    margin-right: 10px; /* Space between icon and text */
    font-size: 1.5rem; /* Icon size */
    color: #808080; /* Gray color for the envelope */
    opacity: 0.8; /* Slightly dimmed appearance */
    transition: opacity 0.3s ease; /* Smooth transition for hover effects */
}

/* Email Text Styling */
.profile-info .email-container .email-text {
    font-size: 1rem; /* Slightly smaller font for text */
    color: #808080; /* Match text color to branding */
    font-weight: 500; /* Slightly bold font for emphasis */
    display: inline-block; /* Keeps text aligned with the icon */
    line-height: 1; /* Ensures proper vertical alignment */
}

/* Hover Effect for Email */
.profile-info .email-container:hover .email-icon {
    opacity: 1; /* Fully visible icon on hover */
}

.profile-info .email-container:hover {
    background-color: rgba(128, 128, 128, 0.2); /* Slightly darker gray background */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

/* Logs Section */
.logs-section {
    padding: 20px;
    background-color: var(--section-background);
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 90%; /* Use 90% of the screen width */
    margin: 0 auto;
    width: 100%; /* Allow full width within the container */
}

.logs-section h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: var(--primary-color);
    text-align: center;
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 10px;
}

.logs-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.logs-section li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--card-background);
    padding: 15px 25px; /* Increased padding for better spacing */
    margin-bottom: 15px; /* Increased gap between items */
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
    transition: transform 0.2s, box-shadow 0.3s;
}

.logs-section li:hover {
    transform: translateY(-2px);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
}

.logs-section li strong {
    font-size: 1.2rem; /* Increased font size for emphasis */
    color: var(--text-color);
    flex: 2;
}

.logs-section li .game-name {
    font-weight: bold;
    font-size: 1.1rem; /* Slightly larger text */
    color: var(--link-color);
    text-decoration: none;
    margin-left: 15px; /* Better spacing from activity */
    flex: 2;
}

.logs-section li .status-badge {
    padding: 8px 20px; /* Increased padding for better visibility */
    font-size: 1rem; /* Larger font for readability */
    font-weight: bold;
    border-radius: 5px;
    text-transform: uppercase;
    color: white;
    text-align: center;
    margin-left: 15px; /* Spacing between game name and badge */
    flex: 1;
}

.logs-section li .status-badge.pending {
    background-color: #ffa500;
}

.logs-section li .status-badge.approved {
    background-color: #71cc74;
}

.logs-section li .status-badge.completed {
    background-color: #4caf50;
}

.logs-section li .status-badge.rejected {
    background-color: #f44336;
}

.logs-section li .timestamp {
    font-size: 1rem; /* Slightly larger timestamp font */
    color: var(--secondary-text-color);
    flex: 1.5;
    text-align: right;
}

/* Adjust spacing between sections */
.logs-section + .notifications-section {
    margin-top: 20px;
}

.security-section {
    margin: 20px;
    padding: 20px;
    background: var(--card-background);
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    max-width: 100%;
    overflow: hidden; /* Prevent overflow */
    box-sizing: border-box; /* Include padding in size calculations */
}

.security-section h3 {
    font-size: 24px;
    color: var(--text-color);
    grid-column: 1 / -1; /* Span the title across both columns */
    text-align: center;
}

.security-form {
    background: var(--section-background);
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    box-sizing: border-box; /* Include padding in size calculations */
    overflow: hidden; /* Prevent overflow */
    max-width: 100%;
}

.security-form h4 {
    font-size: 18px;
    color: var(--text-color);
    margin-bottom: 10px;
}

.security-form input,
.security-form button {
    width: 100%;
    max-width: 100%; /* Prevent inputs from exceeding the card's width */
    padding: 10px;
    margin: 10px 0;
    border-radius: 6px;
    color: var(--text-color);
    background: var(--input-background);
    border: 1px solid var(--border-color);
    box-sizing: border-box; /* Include padding in width calculations */
}

.security-form button {
    background-color: var(--button-background);
    cursor: pointer;
}

.security-form button:hover {
    background-color: var(--hover-background);
}

.qr-code-container {
    text-align: center;
    margin-top: 20px;
}

.qr-code-container img {
    max-width: 150px;
    height: auto;
    margin: 10px 0;
}

.conversations-sidebar {
    flex: 0 0 300px; /* Increased width from 250px to 300px */
    background-color: var(--card-background);
    border-right: 1px solid var(--border-color);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;
}

.conversations-sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.conversations-sidebar li {
    display: flex; /* Flexbox for horizontal alignment */
    align-items: center; /* Vertically align avatar and username */
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: var(--input-background);
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}


.conversations-sidebar li .conversation-details-wrapper {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center avatar, username, and button */
    gap: 10px; /* Add space between avatar, username, and button */
}

.conversations-sidebar li.active,
.conversations-sidebar li:hover {
    background-color: var(--hover-background);
    transform: translateX(5px);
}

.conversation-avatar {
    width: 50px; /* Fixed width */
    height: 50px; /* Fixed height */
    border-radius: 50%;
    margin-right: 15px; /* Space between avatar and username */
    background-color: var(--input-background);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-shrink: 0; /* Prevent shrinking */
}


.conversation-details {
    display: flex;
    flex-direction: column; /* Stack username and other details */
    overflow: hidden; /* Prevent the layout from breaking */
    min-width: 0; /* Ensure proper ellipsis behavior for text */
}


.conversation-avatar img {
    width: 100%; /* Ensure the image fills the avatar container */
    height: 100%; /* Ensure the image fills the avatar container */
    object-fit: cover; /* Maintain aspect ratio and cover the area */
    border-radius: 50%; /* Keep the image round */
}

.conversation-username {
    font-size: 1rem;
    font-weight: bold;
    color: var(--text-color);
    white-space: nowrap; /* Prevent wrapping on a single line */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Show ellipsis for long text */
    cursor: pointer; /* Indicate interactivity for tooltips */
}

.conversation-username:hover::after {
    content: attr(data-full-username); /* Display full username on hover */
    position: absolute;
    background: var(--tooltip-background, #333);
    color: var(--tooltip-text, #fff);
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.9rem;
    white-space: nowrap;
    z-index: 10;
    top: -25px; /* Adjust based on position */
    left: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Disable hover effect for announcements */
.conversation.announcements:hover {
    transform: none;
    cursor: default; /* Optional: Change cursor to default */
}

/* Ensure active styling still works for announcements */
.conversation.announcements.active {
    background-color: var(--hover-background);
}

.conversation-username-wrap {
    word-wrap: break-word; /* Allow multi-line wrapping */
    white-space: normal; /* Enable wrapping */
    overflow: visible; /* Prevent cutting off */
}

.unread-indicator {
    background-color: #ff4d4d; /* Red for unread messages */
    color: white;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 15px;
    margin-left: 10px;
    white-space: nowrap;
}

.conversation.unread {
    border: 2px solid red; /* Red edge for unread messages */
    border-radius: 8px;   /* Optional: adjust the radius to fit your design */
    padding: 5px;         /* Optional: adjust padding for better spacing */
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.5); /* Optional: subtle glow effect */
}

.unread-count {
    display: inline-block;
    margin-left: 10px;
    padding: 2px 6px;
    font-size: 0.75rem;
    color: white;
    background-color: red;
    border-radius: 12px;
    font-weight: bold;
}

.block-button,
.unblock-button {
    margin-left: 10px;
    padding: 4px 8px;
    font-size: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.chat-delete-button {
    margin-left: 10px;
    background-color: red;
    margin-top: 10px;
    color: white;
    padding: 4px 8px;
    font-size: 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.block-button {
    background-color: red;
    color: white;
    margin-left: auto; /* Pushes the button to the right */
}


.unblock-button {
    background-color: green;
    color: white;
}


.unread-indicator {
    color: red;
    font-size: 1.2rem;
    margin-left: 8px;
    vertical-align: middle;
}


/* Badge Styles */
.badge {
    display: inline-block;
    color: var(--button-text);
    padding: 2px 8px;
    font-size: 0.8rem;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: bold;
}

.badge.admin {
    background-color: #d9534f;
}

.badge.moderator {
    background-color: green;
}

.badge.member {
    background-color: orange;
}

.badge.user {
    background-color: #5cb85c;
}

/* Utility */
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.notification-link {
    color: var(--link-color);
    text-decoration: none;
    transition: color 0.3s ease;
}

.notification-link:hover {
    color: var(--hover-background);
    text-decoration: underline;
}

/* Messages Wrapper */
.messages-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    height: calc(100vh - 200px); /* Adjust based on your header/footer */
    overflow: hidden;
}


/* New Message Section */
.new-message-section {
    padding: 15px;
    background-color: var(--card-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.user-search-input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--input-background);
    color: var(--input-text);
    margin-bottom: 10px;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;
}

.user-search-input:focus {
    border-color: var(--primary-color, #007bff);
    box-shadow: 0 0 6px rgba(0, 123, 255, 0.4);
}

.new-message-select {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    background-color: var(--input-background);
    color: var(--input-text);
    font-size: 0.9rem;
}

/* Button to Start Chat */
.new-message-section button {
    padding: 10px 15px;
    font-size: 1rem;
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.new-message-section button:hover {
    background-color: var(--hover-background);
    transform: scale(1.05);
}

.new-message-section button:disabled {
    background-color: var(--disabled-background, #ccc);
    color: var(--disabled-text, #666);
    cursor: not-allowed;
}

/* User Search Results */
.user-search-results {
    list-style: none;
    padding: 10px; /* Add space inside the container */
    margin: 0;
    max-height: 250px;
    overflow-y: auto; /* Allow vertical scrolling */
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 100;
    box-sizing: border-box; /* Include padding and border in dimensions */
}

/* Search Result Usernames */
.user-result-name {
    font-size: 1rem;
    font-weight: bold;
    color: var(--text-color);
    white-space: nowrap; /* Prevent text wrapping */
    overflow-y: hidden; /* Hide overflow on the username itself */
    cursor: pointer; /* Indicate interactivity */
    position: relative; /* Needed for positioning the tooltip */
}

/* New Chat Role Badge Styling */
.nchat-role-badge {
    display: block; /* Ensure it appears on its own line */
    margin: 4px auto 0; /* Center and add spacing from username */
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: bold;
    color: white;
    text-align: center;
    text-transform: capitalize;
    width: fit-content; /* Size adjusts to the text */
}

/* Role-specific Styles */
.nchat-role-badge.admin {
    background-color: #d9534f; /* Admin badge color */
}

.nchat-role-badge.moderator {
    background-color: green; /* Moderator badge color */
}

.nchat-role-badge.member {
    background-color: orange; /* Member badge color */
}

.nchat-role-badge.user {
    background-color: #5cb85c; /* User badge color */
}

.nchat-role-badge.default {
    background-color: gray; /* Default badge color for undefined roles */
}

.toggle-older-button {
    width: 100%;
    margin-top: 10px;
    padding: 8px 12px;
    background-color: var(--button-background);
    border: none;
    border-radius: 5px;
    color: var(--text-color);
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
}

.toggle-older-button:hover {
    background-color: var(--hover-background);
}


/* Chat Section */
.chat-section {
    flex: 1; /* Allow section to grow and fill available space */
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure it uses the full height */
    max-height: calc(100vh - 120px); /* Adjust for header/footer */
    padding: 10px;
    background-color: var(--card-background);
    overflow: hidden; /* Prevent overflow issues */
}

/* Chat Messages */
.chat-messages {
    flex: 1; /* Occupy remaining vertical space */
    overflow-y: auto; /* Enable scrolling */
    padding: 10px;
    background-color: var(--input-background);
    margin-bottom: 10px; /* Space for input box */
    border-radius: 5px;
}

.chat-message {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px 15px;
    border-radius: 8px;
    word-wrap: break-word;
    background-color: var(--message-background);
}

.chat-message-header {
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: space-between; /* Space out items horizontally */
    margin-bottom: 5px; /* Add space between header and content */
}

.chat-message .username {
    font-weight: bold;
    font-size: 1rem;
    color: var(--text-color);
    margin-right: 10px; /* Space between username and timestamp */
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden;
    text-overflow: ellipsis;
}

.chat-message .timestamp {
    font-size: 0.85rem;
    color: var(--secondary-text-color);
    margin-left: auto; /* Push to the far right */
    white-space: nowrap; /* Prevent line breaks */
}

.chat-message-content {
    font-size: 1rem;
    white-space: pre-wrap; /* Preserves spaces and line breaks */
    word-break: break-word; /* Prevents long words from breaking the layout */
    color: var(--text-color);
    margin-top: 5px;
}

.chat-message .role-badge {
    margin-right: 8px; /* Space between the badge and username */
}

.chat-message.sent {
    background-color: rgba(0, 123, 255, 0.1); /* Light blue */
    align-self: flex-end;
    border-right: 5px solid #007bff;
}

.chat-message.received {
    background-color: rgba(40, 167, 69, 0.1); /* Light green */
    align-self: flex-start;
    border-left: 5px solid #28a745;
}

a {
    color: var(--link-color);
    text-decoration: none;
    word-break: break-word; /* Prevent long URLs from breaking layout */
}

a:hover {
    text-decoration: underline;
}

.message-content br,
.chat-message-content br {
    display: block; /* Ensure line breaks behave like new lines */
    margin: 0; /* Reset default browser spacing */
}

/* Chat Input */
.chat-input {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: var(--card-background);
    border-top: 1px solid var(--border-color);
    flex-shrink: 0; /* Prevent it from shrinking */
}


/* Textarea for Input */
.chat-input textarea {
    flex: 1; /* Take up all available horizontal space */
    font-size: 1rem;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
    resize: none;
    height: 40px; /* Fixed height */
    max-height: 80px; /* Prevent excessive growth */
    overflow-y: auto; /* Scrollable if content overflows */
    background-color: var(--input-background);
    color: var(--input-text);
}

/* Send Button */
.chat-input button {
    flex-shrink: 0; /* Ensure it doesn’t shrink */
    font-size: 1rem;
    padding: 10px 15px;
    background-color: var(--button-background);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.chat-input button:hover {
    background-color: var(--hover-background);
}

.role-badge {
    display: inline-block; /* Ensures the badge is only as wide as its content */
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: capitalize;
    border-radius: 4px;
    padding: 4px 8px;
    color: white;
    background-color: gray; /* Default background color */
    white-space: nowrap; /* Prevent text wrapping */
    max-width: fit-content; /* Automatically adjusts to the text width */
    overflow: hidden; /* Prevent content overflow */
    text-align: center; /* Center text */
}

.chat-message .role-badge.admin {
    background-color: #d9534f; /* Admin badge color */
}

.chat-message .role-badge.moderator {
    background-color: green; /* Moderator badge color */
}

.chat-message .role-badge.member {
    background-color: orange; /* Member badge color */
}

.chat-message .role-badge.user {
    background-color: #5cb85c; /* User badge color */
}

.chat-message .role-badge.default {
    background-color: gray; /* Default badge color for undefined roles */
}

/* Custom Role Badge for Announcements Messages */
.role-badge.system-badge {
    background-color: #ff5722; /* Orange for announcements badge */
    color: white;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 4px 8px;
    margin-right: 10px; /* Space between badge and timestamp */
    display: inline-block;
}


.badge-icons {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;    /* Centers vertically */
    gap: 10px;
    margin-bottom: 10px;
}

.badge-icon {
    font-size: 1.5em;
    cursor: pointer;
    text-align: center;      /* Ensures text inside is centered */
}


.settings-option-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: var(--card-background);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.settings-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid var(--border-color);
}

.settings-option:last-child {
    border-bottom: none; /* Remove border for the last item */
}

.settings-option span {
    font-size: 1rem;
    font-weight: bold;
    color: var(--text-color);
}

.toggle-switch {
    position: relative;
    width: 50px;
    height: 24px;
}

.toggle-switch input {
    opacity: 0; /* Hide checkbox */
    width: 0;
    height: 0;
}

.toggle-switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 12px;
}

.toggle-switch .slider::before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

.toggle-switch input:checked + .slider {
    background-color: #4caf50;
}

.toggle-switch input:checked + .slider::before {
    transform: translateX(26px);
}

.settings-category {
    margin-bottom: 25px; /* Add space between categories */
    padding-bottom: 10px; /* Add spacing inside the category */
    border-bottom: 1px solid var(--border-color); /* Subtle separator between categories */
}

.settings-category:last-child {
    border-bottom: none; /* Remove border for the last category */
}

.settings-category h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 15px; /* Space below the heading */
    text-transform: uppercase;
    letter-spacing: 0.5px; /* Slightly spaced-out text */
}

.alert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    animation: fadeIn 0.5s ease; /* Smooth entry animation */
}

.alert-success {
    background-color: #d4edda; /* Light green */
    color: #155724; /* Dark green text */
    border: 1px solid #c3e6cb;
}

.alert-error {
    background-color: #f8d7da; /* Light red */
    color: #721c24; /* Dark red text */
    border: 1px solid #f5c6cb;
}

.alert-icon {
    margin-right: 10px;
    font-size: 1.5rem;
    flex-shrink: 0;
}

.alert-message {
    flex: 1;
    text-align: left;
}

.alert-close {
    background: none;
    border: none;
    font-size: 1.2rem;
    color: inherit;
    cursor: pointer;
    padding: 5px;
    margin-left: 15px;
    flex-shrink: 0;
    transition: opacity 0.3s ease;
}

.alert-close:hover {
    opacity: 0.7;
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

/* Wrapper to center everything */
.discord-connect-wrapper {
    display: flex;
    align-items: center; /* Align vertically */
    justify-content: center; /* Align horizontally */
    position: relative; /* Allow independent positioning of the question mark */
}

/* Container for the button and the info icon */
.discord-connect-container {
    text-align: center; /* Center the button within its container */
    margin-top: 10px;
}

/* Connect Discord Button */
.connect-discord-button {
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: 500;
    background-color: var(--button-background, #007bff);
    color: var(--button-text, #ffffff);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    text-align: center; /* Ensure button text is centered */
}

.connect-discord-button:hover {
    background-color: var(--hover-background, #0056b3);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

/* Info button container */
.info-button-container {
    position: absolute; /* Keeps it positioned relative to the wrapper */
    right: -50px; /* Adjust spacing between the info button and the connect button */
    top: calc(50% + 0.2em); /* Slightly reduce the downward offset */
    transform: translateY(-50%); /* Center it based on its own height */
    display: flex;
    align-items: center; /* Ensure the ? icon is vertically centered */
    justify-content: center;
}


/* Info Button */
.info-button {
    background-color: var(--primary-color, #007bff);
    color: var(--button-text, #ffffff);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.info-button:hover {
    background-color: var(--hover-background, #0056b3);
    transform: scale(1.1);
}

/* Badge Icon Wrapper */
.badge-icon-wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Tooltip Styling */
.badge-icon-wrapper .pbtooltip {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--tooltip-background, #333);
    color: var(--tooltip-text, #fff);
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Hover Tooltip for Desktop */
.badge-icon-wrapper:hover .pbtooltip {
    opacity: 1;
    visibility: visible;
}

/* Click-Based Tooltip for Mobile */
.badge-icon-wrapper.active .pbtooltip {
    opacity: 1;
    visibility: visible;
}


/* Tooltip */
.dtooltip {
    position: absolute;
    top: 50%;
    left: calc(100% + 15px);
    transform: translateY(-50%);
    background-color: var(--background-color, #ffffff);
    color: var(--text-color, #333333);
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: none;
    width: 300px;
    font-size: 14px;
    text-align: left;
    line-height: 1.8;
}

/* Tooltip title */
.dtooltip h4 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--primary-color, #007bff);
    border-bottom: 2px solid var(--primary-color, #007bff);
    padding-bottom: 5px;
}

/* Tooltip paragraph */
.dtooltip p {
    margin-bottom: 10px;
    color: var(--text-color, #555555);
}

/* Tooltip list */
.dtooltip ul {
    padding-left: 20px;
    margin: 10px 0;
    list-style-type: disc;
}

.dtooltip ul li {
    margin-bottom: 8px;
    font-size: 14px;
    color: var(--text-color, #333333);
    line-height: 1.6;
}

.dtooltip ul li::before {
    content: "✔";
    color: var(--primary-color, #007bff);
    margin-right: 8px;
    font-size: 14px;
}

/* Tooltip arrow */
.dtooltip::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: transparent var(--background-color, #ffffff) transparent transparent;
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Show tooltip on hover */
.info-button-container:hover .dtooltip {
    display: block;
    animation: slideIn 0.4s ease-out;
}

/* System Messages in Chat */
.chat-messages .chat-message.system {
    background-color: rgba(0, 123, 194, 0.3); /* Light yellow background for system messages */
    border-left: 5px solid #d9534f; /* Gold border for system messages */
    padding: 10px 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    word-wrap: break-word;
    font-size: 0.95rem;
    color: var(--text-color); /* Keep the text color consistent */
}

.emoji-picker-wrapper {
    position: relative;
    display: inline-block;
}

.emoji-picker {
    position: absolute;
    bottom: 50px;
    right: 10px;
    z-index: 1000;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.emoji-button {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.emoji-button:hover {
    color: #555;
}

/* About Me Section */
.about-me-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--card-background);
    padding: 20px 30px; /* Extra padding for spacing */
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box; /* Ensure padding is included in width */
}

/* About Me Title */
.about-me-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 15px;
    border-bottom: 3px solid var(--primary-color);
    padding-bottom: 8px;
    width: 100%; /* Align with container */
}

/* Displayed About Me Text */
.about-me-display {
    width: 100%;
    text-align: center; /* Center-align text */
    word-wrap: break-word; /* Ensure long text wraps properly */
}

.about-me-display p {
    font-size: 1rem;
    line-height: 1.6;
    color: var(--text-color);
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--input-background);
    margin-bottom: 15px; /* Spacing below text */
}

/* Edit Button */
.about-me-display button {
    padding: 10px 20px;
    background-color: var(--button-background);
    color: var(--button-text);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.about-me-display button:hover {
    background-color: var(--hover-background);
    transform: scale(1.05);
}

/* Editing State */
.about-me-edit {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.about-me-edit textarea {
    width: 100%;
    height: 120px; /* Slightly taller for better readability */
    padding: 10px;
    font-size: 1rem;
    line-height: 1.5;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--input-background);
    color: var(--input-text);
    resize: none;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
}

.about-me-buttons {
    display: flex;
    gap: 10px;
    justify-content: center; /* Center-align buttons */
    width: 100%;
}

.about-me-buttons button {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.about-me-buttons button.save {
    background-color: var(--button-background);
    color: var(--button-text);
}

.about-me-buttons button.save:hover {
    background-color: var(--hover-background);
    transform: scale(1.05);
}

.about-me-buttons button.cancel {
    background-color: var(--error-background, #f44336);
    color: var(--button-text, white);
}

.about-me-buttons button.cancel:hover {
    background-color: var(--error-hover-background, #d32f2f);
    transform: scale(1.05);
}

/* Saved Tables Section Styling */
.saved-tables-section {
    padding: 20px;
    background-color: var(--card-background);
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    overflow: hidden;
}

/* Individual Saved Table Item */
.saved-table-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: var(--input-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
    transition: transform 0.2s, box-shadow 0.3s;
}

.saved-table-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Table Name and Version */
.saved-table-item div {
    flex: 1;
    font-size: 1rem;
    font-weight: bold;
    color: var(--text-color);
}

/* Buttons Container */
.saved-table-item button {
    padding: 10px 15px;
    font-size: 0.9rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    margin-left: 10px; /* Add spacing between buttons */
}


/* Download Button */
.download-table-button {
    background-color: #4caf50;
    color: white;
}

.download-table-button:hover {
    background-color: #45a049;
    transform: scale(1.05);
}

/* Remove Button */
.remove-table-button {
    background-color: #f44336;
    color: white;
}

.remove-table-button:hover {
    background-color: #e53935;
    transform: scale(1.05);
}


/* Slide-in animation */
@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(10px) translateY(-50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0) translateY(-50%);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    /* Page Wrapper */
    .page-wrapper {
        flex-direction: column; /* Stack sidebar and main content */
    }

    /* Sidebar */
    .sidebar {
        width: 97%; /* Full width on smaller screens */
        box-shadow: none; /* Remove the shadow for cleaner stacking */
        padding: 10px;
    }

    .sidebar h2 {
        font-size: 1.25rem; /* Slightly smaller title */
        text-align: center; /* Center-align title */
    }

    .sidebar .menu a,
    .menu button {
        font-size: 0.9rem; /* Smaller font size for buttons and links */
        padding: 8px;
    }

    .logout-button {
        justify-content: center; /* Center logout button */
    }

    /* Main Content */
    .main-content {
        padding: 20px; /* Reduce padding */
        gap: 15px;
    }

    /* Profile Section */
    .profile-section {
        gap: 15px; /* Reduce gaps */
    }

    .avatar-and-info {
        gap: 10px; /* Reduce gaps */
    }

    .avatar-container {
        margin-bottom: 10px; /* Add spacing for better flow */
    }

    .avatar-image {
        width: 80px;
        height: 80px;
    }

    /* Forms */
    .profile-update-sections {
        flex-direction: column; /* Stack forms vertically */
        gap: 15px; /* Reduce gap */
    }

    .profile-edit-form input,
    .password-edit-form input {
        font-size: 0.8rem; /* Slightly smaller input font */
    }

    .profile-edit-form button,
    .password-edit-form button {
        font-size: 0.8rem; /* Slightly smaller button font */
    }

    /* Notifications and Logs Sections */
    .notifications-section ul,
    .logs-section ul {
        margin: 0; /* Remove extra margin */
    }

    .notifications-section li,
    .logs-section li {
        padding: 8px; /* Reduce padding */
    }

    .logs-section {
        padding: 15px;
        background-color: var(--section-background);
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
        width: 100%;
        margin: 0 auto;
    }

    .logs-section h3 {
        font-size: 1.5rem;
        margin-bottom: 15px;
        color: var(--primary-color);
        text-align: center;
        border-bottom: 2px solid var(--primary-color);
        padding-bottom: 8px;
    }

    .logs-section ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .logs-section li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        background-color: var(--card-background);
        padding: 12px 15px;
        margin-bottom: 10px;
        border-radius: 6px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    }

    .logs-section li strong {
        font-size: 1rem;
        color: var(--text-color);
        margin-bottom: 5px;
    }

    .logs-section li .game-name {
        font-weight: bold;
        font-size: 0.9rem;
        color: var(--link-color);
        text-decoration: none;
        margin-bottom: 5px;
    }

    .logs-section li .game-name:hover {
        text-decoration: underline;
    }

    .logs-section li .status-badge {
        padding: 5px 10px;
        font-size: 0.8rem;
        font-weight: bold;
        border-radius: 4px;
        text-transform: uppercase;
        color: white;
        text-align: center;
        margin-bottom: 5px;
    }

    .logs-section li .status-badge.pending {
        background-color: #ffa500;
    }

    .logs-section li .status-badge.completed {
        background-color: #4caf50;
    }

    .logs-section li .status-badge.rejected {
        background-color: #f44336;
    }

    .logs-section li .timestamp {
        font-size: 0.8rem;
        color: var(--secondary-text-color);
        margin-top: 5px;
        text-align: left;
    }

    /* Alerts */
    .alert {
        font-size: 0.9rem; /* Smaller alert font */
    }
    .profile-info p {
        flex-direction: column; /* Stack elements vertically */
        gap: 8px; /* Adjust gap for smaller screen */
        text-align: center; /* Center-align content */
    }

    .profile-info p.discord-info .discord-username {
        text-align: left; /* Retain alignment on mobile */
    }

    /* Messages Wrapper */
    .messages-wrapper {
        flex-direction: column; /* Stack sidebar and chat section */
        height: 100vh; /* Full screen height */
        overflow: hidden; /* Prevent unwanted scroll */
    }

    /* Chat Section */
    .chat-section {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 60px); /* Ensure it adjusts dynamically */
        overflow: hidden; /* Prevent overflow issues */
    }

    .chat-messages {
        flex: 1; /* Occupy remaining space */
        overflow-y: auto;
    }

    .chat-input {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;
        background-color: var(--card-background);
        border-top: 1px solid var(--border-color);
        box-sizing: border-box;
        position: sticky; /* Sticks to the bottom */
        bottom: 0; /* Align with bottom of the viewport */
    }

    .chat-input textarea {
        flex: 1;
        font-size: 0.9rem;
        padding: 8px;
        height: 40px;
        max-height: 80px;
        resize: none; /* Prevent resizing */
    }

    .chat-input button {
        font-size: 0.9rem;
        padding: 8px 12px;
    }
    .settings-option-container {
        padding: 10px; /* Add inner padding */
        margin: 0 auto; /* Center horizontally with equal spacing on both sides */
        width: calc(100% - 20px); /* Ensure consistent spacing */
        gap: 8px; /* Reduce gap between items */
        max-width: 100%; /* Prevent overflow */
        box-sizing: border-box; /* Include padding and margin in width */
    }
}

@media (max-width: 480px) {
    /* Chat Section Adjustments for Smaller Screens */
    .chat-section {
        height: calc(100vh - 50px); /* Further reduce for smaller screens */
    }

    .chat-input {
        padding: 8px;
        gap: 8px;
    }

    .chat-input textarea {
        font-size: 0.85rem;
        padding: 6px;
    }

    .chat-input button {
        font-size: 0.85rem;
        padding: 6px 10px;
    }
}

@media (min-width: 1024px) {
    /* Sidebar */
    .sidebar {
        width: 200px; /* Increase sidebar width */
    }

    /* Main Content */
    .main-content {
        padding: 20px; /* Increase padding for spacious layout */
    }

    .card {
        padding: 20px; /* Larger card padding */
    }

    .profile-edit-form button,
    .password-edit-form button {
        font-size: 1rem; /* Larger button font */
    }
    
    
}
