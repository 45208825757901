/* Support Page Styling */
.support-container {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 40px 20px;
    background-color: var(--section-background);
    color: var(--text-color);
}

/* Header Styling */
.support-header {
    text-align: center;
    margin-bottom: 40px;
}

.support-header h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--text-color);
}

.support-header p {
    font-size: 1.2rem;
    color: var(--text-color-secondary);
}

/* FAQ Section Styling */
.faq-section {
    margin-bottom: 40px;
}

.faq-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: var(--text-color);
}

.faq-list {
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--card-background);
}

.faq-item {
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid var(--border-color);
    transition: background-color 0.3s ease;
}

.faq-item:last-child {
    border-bottom: none;
}

.faq-item.open {
    background-color: var(--section-background); /* Light mode section background */
}

body.dark .faq-item.open {
    background-color: var(--input-background); /* Dark mode input background */
}

.faq-question {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--text-color);
}

.faq-answer {
    font-size: 1rem;
    color: var(--text-color);
    margin-top: 10px;
}

.faq-answer a {
    color: var(--link-color); /* Subtle color for links */
    text-decoration: underline;
}

/* Contact Section Styling */
.contact-section {
    text-align: left;
    padding: 20px;
    border-radius: 8px;
    background-color: var(--card-background);
}

.contact-section h2 {
    font-size: 1.8rem;
    color: var(--text-color);
    margin-bottom: 15px;
}

.contact-section p {
    font-size: 1rem;
    margin-bottom: 10px;
    color: var(--text-color);
}

.support-link {
    color: var(--link-color);
    text-decoration: underline;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    font-size: 1.1rem;
    color: var(--text-color);
    margin-bottom: 8px;
}