/* General Page Layout */
.tools-page {
    padding: 60px 30px;
    background-color: var(--background-color);
    font-family: "Inter", Arial, sans-serif;
    color: var(--text-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Header Section */
  .tools-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .tools-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--text-color);
    margin-bottom: 10px;
  }
  
  .tools-subtitle {
    font-size: 1.2rem;
    color: var(--text-color-secondary);
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* Error and Loading States */
  .tools-error,
  .tools-loading {
    text-align: center;
    margin: 20px 0;
    font-size: 1.1rem;
    color: var(--error-color);
  }
  
  /* Tools Container */
  .tools-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  
  /* Category Section */
  .tools-category {
    background: var(--section-background);
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
  
  .tools-category-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: var(--text-color);
    text-align: center;
    border-bottom: 2px solid var(--border-color);
    padding-bottom: 10px;
  }
  
  /* Tools Grid */
  .tools-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    margin-top: 20px;
  }
  
  /* Tool Card */
  .tool-card {
    background: var(--card-background);
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .tool-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  .tool-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--text-color);
    margin-bottom: 15px;
  }
  
  .tool-description {
    font-size: 0.95rem;
    color: var(--text-color-secondary);
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  /* Download Button */
  .tool-download-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: var(--button-text);
    background: var(--button-background);
    border: none;
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .tool-download-button:hover {
    background-color: var(--hover-background);
    transform: scale(1.05);
  }
  
  /* Empty State */
  .tools-empty {
    text-align: center;
    color: var(--text-color-secondary);
    font-size: 1.2rem;
    margin-top: 50px;
  }
  