/* Navbar container */
.navbar {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg, #4B0082, #4682B4);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
    border-bottom: 1px solid #aaa;
}

/* Navigation links */
.nav-links {
    display: flex;
    gap: 20px; /* Even spacing between links */
}

.nav-link {
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 12px;
    border-radius: 4px;
    color: white;
    background-clip: text; /* Ensures the text effect works */
    transition: background 0.5s ease, color 0.5s ease, transform 0.3s ease;
    cursor: pointer;
    position: relative;
    z-index: 1; /* Ensures hover effects work properly */
}

.nav-link::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background: var(--link-color); /* Slightly different tones */
    transition: width 0.5s ease;
    z-index: -1;
}

.nav-link:hover::before {
    width: 100%;
}

.nav-link:hover {
    background: var(--link-color); /* Shifted purple and blue shades */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: scale(1.05); /* Subtle zoom effect */
}

/* Auth links */
.auth-links {
    display: flex;
    align-items: center;
    gap: 10px; /* Increased spacing between elements */
}

.auth-links .nav-link {
    color: white;
    text-decoration: none;
    font-weight: bold;
    padding: 8px 12px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.auth-links .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: #007bff;
}

/* Admin panel link */
.admin-dropdown-item {
    font-weight: bold;
    color: #e74c3c; /* Admin link color */
    padding: 12px 16px;
    transition: all 0.3s ease;
    border-radius: 8px; /* Rounded corners for better design */
}

.admin-dropdown-item:hover {
    background-color: rgba(231, 76, 60, 0.1);
    color: #c0392b;
}

/* Logo */
.logo {
    height: 40px;
    width: auto;
    cursor: pointer;
}


/* Hamburger menu */
.hamburger-button {
    background: none;
    border: none;
    color: #fff;
    font-size: 32px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.hamburger-button:hover {
    color: #007bff;
}

/* Dropdown menu */
.dropdownMenu {
    position: absolute;
    top: 90px;
    right: 20px;
    border-radius: 12px; /* Smooth corners for a clean look */
    width: 150px;
    padding: 10px; /* Compact padding for better structure */
    background-color: var(--card-background);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Softer shadow for a modern look */
    border: 1px solid #ddd; /* Subtle border */
    z-index: 1000;
    animation: fadeIn 0.2s ease-in-out; /* Smooth fade-in animation */
    overflow: hidden;
}

/* Dark mode styles for dropdown */
body.dark .dropdownMenu {
    background-color: #333333;
    border: 1px solid #444444;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
}

body.dark .dropdownItem {
    color: #e0e0e0;
}

body.dark .dropdownItem:hover {
    background-color: #222222; /* Darker background on hover */
    color: #ffffff;
}

body.dark .themeToggleButton:hover {
    background-color: #222222; /* Darker background on hover */
    color: #ffffff;
    box-shadow: inset 0 0 0 1px #ddd;
}

/* Dropdown items */
.dropdownItem {
    display: block;
    padding: 12px 16px;
    font-size: 18px;
    text-decoration: none;
    color: var(--text-color);
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    overflow: hidden;
    
}

.dropdownItem:hover {
    background-color: #e6e6e6; /* Slightly darker gray on hover */
    color: #000000; /* Ensure text remains clear */
    box-shadow: inset 0 0 0 1px #ddd;
    
}

/* Divider for sections */
.dropdown-divider {
    height: 1px;
    background-color: #ddd;
    margin: 10px 0;
    border: none;
}

/* Logout button */
.logoutButton {
    width: 100%;
    padding: 12px 16px;
    font-size: 18px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: #d12c28; /* Persistent red for logout */
    text-align: left;
    display: flex;
    align-items: center;
    gap: 10px; /* Space between icon and text */
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.logoutButton:hover {
    background-color: rgba(209, 44, 40, 0.1); /* Subtle red background on hover */
    color: #a8322a; /* Darker red for hover */
    box-shadow: inset 0 0 0 1px #ddd;
}

/* Theme toggle button */
.themeToggleButton {
    width: 100%;
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: var(--text-color);
    text-align: left;
    display: flex;
    align-items: center;
    gap: 5px; /* Space between icon and text */
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
    
}

.themeToggleButton:hover {
    background-color: #e6e6e6;
    color: #000; /* Ensure text remains clear */
    box-shadow: inset 0 0 0 1px #ddd;
}

/* Theme icon */
.theme-icon {
    font-size: 16px;
    color: inherit;
    transition: color 0.3s ease;
}

.themeToggleButton:hover .theme-icon {
    color: #007bff;
    
}

/* Fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Mobile container for hamburger and avatar */
.mobile-controls {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Mobile menu */
.mobile-menu {
    position: absolute;
    top: 90px;
    right: 10px;
    background-color: var(--card-background);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    z-index: 1000;
    padding: 20px;
}

.mobile-menu-item {
    text-decoration: none;
    color: var(--text-color);
    padding: 15px 20px;
    font-size: 18px;
    display: block;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.mobile-menu-item:hover {
    background-color: #f0f0f0;
}

/* Avatar */
.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    border: 3px solid white;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.avatar:hover {
    border-color: #007bff;
}

.default-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ddd;
    cursor: pointer;
}

.default-avatar-icon {
    width: 24px;
    height: 24px;
    color: #555;
}

/* Avatar Wrapper */
.avatar-wrapper {
    position: relative;
    display: inline-block;
}

.notification-badge {
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    background-color: red;
    color: white;
    border-radius: 50%;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.unread-count-nav {
    background-color: red;
    color: white;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -2px; /* Adjust vertical alignment */
    margin-left: 5px; /* Adds spacing between the button and the badge */
}



/* Responsive adjustments */
@media (max-width: 768px) {
    .hamburger-button {
        font-size: 28px;
    }

    .avatar,
    .default-avatar {
        width: 40px;
        height: 40px;
    }

    .dropdownItem {
        font-size: 20px;
        padding: 10px 14px;
    }

    .themeToggleButton,
    .logoutButton {
        font-size: 20px;
        padding: 10px 14px;
    }
}
