/* Matrix Container */
.matrix-container {
    position: relative;
    color: #0f0;
    font-family: monospace;
    background-color: black;
    overflow: hidden;
    padding: 50px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none; /* Prevents text selection */
    z-index: 1;
}

/* Screen Artifact Overlay */
.matrix-container::before, 
.matrix-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0.3; /* Increased opacity for visibility */
    z-index: 0;
}

.matrix-container::before {
    background: repeating-linear-gradient(
        0deg,
        rgba(0, 255, 0, 0.1),
        rgba(0, 255, 0, 0.1) 2px,
        transparent 2px,
        transparent 5px
    );
    animation: artifactMove 8s infinite linear;
}

.matrix-container::after {
    background: repeating-linear-gradient(
        90deg,
        rgba(0, 255, 0, 0.1),
        rgba(0, 255, 0, 0.1) 2px,
        transparent 2px,
        transparent 5px
    );
    animation: artifactMove 12s infinite linear reverse;
}

/* Screen Artifacts: Slow Movement */
@keyframes artifactMove {
    0% { transform: translate(0, 0); }
    100% { transform: translate(20px, 20px); }
}

/* Terminal Output */
.terminal-output {
    font-size: 2rem; /* Increased font size */
    line-height: 1.8;
    white-space: pre-wrap;
    text-align: left;
    color: #0f0;
    animation: textFlicker 1.5s infinite alternate ease-in-out;
    z-index: 1;
}

/* Start Screen */
.start-screen {
    font-size: 2rem; /* Increased font size */
    color: #0f0;
    text-align: center;
    background-color: black;
    cursor: pointer;
    font-family: monospace;
    padding: 20px;
    animation: blink 2s infinite;
}

.start-prompt {
    opacity: 0.8;
}

/* Blinking Animation for Start Prompt */
@keyframes blink {
    0%, 50% { opacity: 1; }
    50%, 100% { opacity: 0; }
}

/* Glitch Effect for Matrix-style Glitch */
.glitch {
    animation: shake 0.15s ease-in-out 3;
}

@keyframes shake {
    0% { transform: translate(1px, 1px); }
    20% { transform: translate(-1px, -1px); }
    40% { transform: translate(1px, -1px); }
    60% { transform: translate(-1px, 1px); }
    80% { transform: translate(-1px, 1px); }
    100% { transform: translate(0, 0); }
}

/* Flicker Animation for Text */
@keyframes textFlicker {
    0%, 100% { color: #0f0; }
    20% { color: #0a0; }
    40% { color: #0c0; }
    60% { color: #050; }
    80% { color: #0b0; }
}

/* Enhanced Glitch effect for the credits text */
.show-credits .terminal-output {
    font-size: 3.2rem; /* Larger font size for the credits text */
    line-height: 1.8;
    text-shadow: -1px 0 red, 1px 0 blue;
    animation: creditsGlitch 1.2s infinite ease-in-out, glitchOpacity 2s infinite alternate;
}

.glitch-link {
    display: inline-block;
    transition: color 0.2s ease;
    position: relative;
    color: #0f0;
    cursor: pointer;
}

.glitch-link:hover {
    animation: intenseGlitch 0.2s steps(5, end) infinite;
    color: #ff00ff; /* Change color on hover */
}

/* Glitch Animation for Credits */
.glitch-text {
    position: relative;
    font-size: 3rem; /* Increased font size */
    color: #0f0;
    animation: creditsGlitch 1.5s infinite ease-in-out, glitchOpacity 2s infinite alternate;
}

@keyframes creditsGlitch {
    0% { 
        text-shadow: 2px 0 red, -2px 0 blue;
    }
    25% {
        text-shadow: -3px 0 red, 3px 0 blue;
    }
    50% {
        text-shadow: -4px 0 red, 4px 0 blue;
    }
    75% {
        text-shadow: 3px 0 red, -3px 0 blue;
    }
    100% { 
        text-shadow: -1px 0 red, 1px 0 blue;
    }
}

/* Opacity Flicker for Glitch */
@keyframes glitchOpacity {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.6; }
}

@keyframes intenseGlitch {
    0% {
        transform: translate(2px, -2px) rotate(0deg);
        color: #0f0;
    }
    25% {
        transform: translate(-3px, 3px) rotate(-1deg);
        color: #ff0000;
    }
    50% {
        transform: translate(3px, -3px) rotate(1deg);
        color: #00ffff;
    }
    75% {
        transform: translate(-2px, 2px) rotate(0deg);
        color: #ff00ff;
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
        color: #0f0;
    }
}