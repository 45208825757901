/* Category Detail Page Container */
.category-detail-container {
    overflow-x: hidden;
    padding: 15px;
}

/* Title Styling */
.category-title {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
    color: var(--text-color); /* Theme-adapted text color */
    text-transform: uppercase;
}

/* Content Layout */
.category-content {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    align-items: flex-start;
    border-top: 2px solid var(--border-color, #e0e0e0); /* Theme-adapted border */
    padding-top: 30px;
    flex-wrap: wrap;
}

/* Media Section */
.category-media {
    flex-basis: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
    box-sizing: border-box;
    border-right: 2px solid var(--border-color, #e0e0e0);
}

/* Slideshow and Images */
.image-slideshow {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
}

.category-screenshot {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

.category-screenshot:hover {
    transform: scale(1.05);
}

/* Slideshow Buttons */
.prev-btn, .next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    border-radius: 50%;
}

.prev-btn {
    left: 10px;
}

.next-btn {
    right: 10px;
}

.show-more-link {
    text-align: center;
    color: var(--link-color);
}

/* Expanded Image Overlay */
.expanded-image-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.expanded-image-container img {
    max-width: 90%;
    max-height: 90%;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}

.image-container {
    position: relative; /* Ensure the badge is positioned relative to this container */
    width: 100%; /* Adjust width as needed */
    max-width: 600px; /* Optional: limit the max width */
}

.close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
}

/* Video Showcase */
.video-showcase {
    margin-top: 20px;
    color: var(--text-color);
    text-align: center;
    background-color: var(--card-background);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

/* Aligning the Description Header */
.description-header {
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: space-between; /* Ensure buttons are spaced properly */
    margin-bottom: 16px; /* Space below the header */
    position: relative; /* Ensure child elements like the toggle stay inside */
    gap: 16px; /* Space between the report button and notification icon */
}

/* Category Description */
.category-description {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: var(--text-color);
}

.category-description h3 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--text-color);
    text-transform: uppercase;
    border-bottom: 2px solid var(--border-color, #e0e0e0);
    padding-bottom: 10px;
}

/* Table Item */
.table-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: var(--card-background);
    border-radius: 6px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.table-item:hover {
    transform: scale(1.02);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

/* Download Button */
.download-link {
    background-color: var(--button-background);
    color: var(--button-text);
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
}

.download-link:hover {
    background-color: var(--button-hover); /* Darker hover effect */
}

/* Disclaimer Section */
.disclaimer {
    font-size: 1rem;
    color: #666;
    text-align: center;
    margin-top: 20px;
    padding: 10px;
    background-color: rgba(255, 77, 77, 0.05);
    border-top: 1px solid var(--border-color, #e0e0e0);
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
}

/* Action Buttons Section */
.action-buttons {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: flex-end; /* Align items to the right */
    margin-top: 20px; /* Add spacing from the description */
}

/* Report Button */
.report-button {
    background-color: #ff4d4f;
    color: white;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.report-button:hover {
    background-color: #d43f3f;
    transform: scale(1.05); /* Slightly enlarge on hover */
}

/* Report Form */
.report-form {
    margin-top: 16px; /* Space above the form */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
}

.success-message {
    color: var(--success-color); /* Use a green color for success */
    background-color: rgba(0, 128, 0, 0.1); /* Light green background */
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    animation: fadeIn 0.5s ease-in-out;
}


.report-textbox {
    width: 100%;
    max-width: 300px; /* Limit the textbox width */
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    resize: none; /* Prevent resizing */
    font-size: 14px;
    line-height: 1.5;
    background-color: var(--input-background);
    color: var(--input-text);
    caret-color: var(--input-text); /* Ensures caret (cursor) matches text color */
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  }

/* Focus state for better visibility */
.report-textbox:focus {
    border-color: var(--link-color);
    outline: none;
    box-shadow: 0 0 5px rgba(52, 152, 219, 0.5); /* Matches link color */
  }


/* Placeholder text color */
.report-textbox::placeholder {
    color: var(--text-color-secondary); /* Subtle placeholder color */
    opacity: 0.8; /* Ensure visibility while keeping it distinct */
  }

.report-submit-button {
    background-color: var(--button-background);
    color: var(--button-text);
    font-size: 14px;
    font-weight: bold;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .report-submit-button:hover {
    background-color: var(--hover-background);
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  

/* Notification Section */
.notification-section {
    display: flex;
    justify-content: flex-end; /* Align to the right */
    margin-bottom: 16px; /* Add space below the button */
}

/* Notification Icon */
.notification-toggle {
    width: 48px;
    height: 48px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

/* Position the badge on top of the image */
.status-badge-overlay {
    position: absolute; /* Position relative to its container */
    top: 2px; /* Close to the top */
    left: 2px; /* Close to the left */
    color: #fff; /* White text */
    padding: 1px 5px; /* Minimal padding */
    font-size: 0.6rem; /* Smaller font size */
    font-weight: bold; /* Keep text bold */
    border-radius: 4px; /* Subtle rounded edges */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2); /* Minimal shadow */
    display: flex; /* Align content flexibly */
    align-items: center; /* Center-align items vertically */
    z-index: 10; /* Ensure it appears above other elements */
    text-transform: uppercase; /* Match the style in the image */
}


/* Green "Working" Badge */
.status-badge-overlay.green {
    background-color: #28a745; /* Green background for "Working" */
    color: white; /* White text */
    border: 2px solid #1e7e34; /* Border for a sharp effect */
}

.status-badge-overlay.yellow {
    background-color: #a5a728; /* Green background for "Working" */
    color: white; /* White text */
    border: 2px solid #a5a728; /* Border for a sharp effect */
}

.status-badge-overlay.red {
    background-color: #bd3232; /* Green background for "Working" */
    color: white; /* White text */
    border: 2px solid #bd3232; /* Border for a sharp effect */
}

/* Status Icon Inside Badge */
.status-badge-icon {
    margin-right: 8px; /* Space between icon and text */
    font-size: 1.4rem; /* Icon size */
}



.notification-icon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Hover Effect for Notification Icon */
.notification-icon:not(.disabled):hover {
    transform: scale(1.1); /* Slight zoom */
}

/* Disabled State */
.notification-icon.disabled {
    opacity: 0.6; /* Faded appearance */
    cursor: not-allowed;
    filter: grayscale(80%);
}

/* Tooltip */
.notification-toggle::after {
    content: attr(data-tooltip); /* Tooltip text */
    position: absolute;
    bottom: 110%; /* Position above the notification icon */
    right: 250px; /* Center align horizontally */
    transform: translateX(250px);
    background-color: rgba(0, 0, 0, 0.8); /* Dark background for contrast */
    color: #fff; /* Ensure the text is white for visibility */
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 0.9rem;
    white-space: nowrap;
    opacity: 0; /* Hidden by default */
    pointer-events: none;
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 1000;
}

.notification-toggle:hover::after {
    opacity: 1; /* Show tooltip on hover */
    transform: translateX(50%) translateY(-5px); /* Slight upward movement */
}

/* Save Latest Button (Match Notification Button Style) */
.save-latest-button-container {
    width: 48px; /* Match notification button size */
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.save-latest-icon {
    width: 100%; /* Fit icon inside the container */
    height: 100%;
    border-radius: 50%; /* Make it circular like the notification button */
    transition: transform 0.3s ease, opacity 0.3s ease;
}


/* Tooltip for Save Button */
.save-latest-button-container::after {
    content: attr(data-tooltip); /* Tooltip text from data-tooltip attribute */
    position: absolute;
    bottom: 110%; /* Position above the button */
    left: -250px; /* Center-align horizontally */
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8); /* Dark tooltip background */
    color: #fff; /* White text for tooltip */
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 0.9rem;
    white-space: nowrap;
    opacity: 0; /* Initially hidden */
    pointer-events: none;
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 1000; /* Ensure it appears above other elements */
}

.save-latest-button-container:hover::after {
    opacity: 1; /* Show tooltip on hover */
    transform: translateX(-50%) translateY(-5px); /* Slight upward animation */
}

.save-latest-button-container.disabled {
    cursor: not-allowed;
}

.feedback-message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
}

.feedback-message.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.feedback-message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    /* General container adjustments */
    .category-detail-container {
        padding: 10px;
        text-align: center; /* Center content */
    }

    /* Title Styling */
    .category-title {
        font-size: 2rem; /* Reduce font size */
        margin-bottom: 20px;
    }

    /* Content Layout */
    .category-content {
        flex-direction: column; /* Stack items vertically */
        gap: 20px; /* Reduce spacing */
        align-items: center; /* Center-align flex items */
        text-align: left; /* Ensure text remains left-aligned */
    }

    /* Media Section */
    .category-media {
        flex-basis: 100%;
        padding-right: 0;
        border-right: none; /* Remove border */
        text-align: center; /* Center media content */
    }

    .image-slideshow {
        max-width: 100%; /* Ensure images fit the container */
    }

    .category-screenshot {
        border-radius: 8px; /* Slightly reduce corner radius */
    }

    /* Video Showcase */
    .video-showcase {
        padding: 15px; /* Adjust padding */
        text-align: center; /* Center video content */
    }

    /* Description Section */
    .category-description {
        flex-basis: 100%; /* Full width */
        text-align: center; /* Center text for a clean layout */
    }

    .category-description h3 {
        font-size: 1.5rem; /* Reduce font size */
    }

    /* Table Item */
    .table-item {
        flex-direction: column; /* Stack content vertically */
        align-items: center; /* Center-align items */
        padding: 10px; /* Adjust padding */
        gap: 15px; /* Add spacing between elements */
        text-align: center; /* Center-align text */
    }

    .download-link {
        width: 100%; /* Full width for easier tapping */
        text-align: center;
    }

    /* Action Buttons Section */
    .action-buttons {
        align-items: center; /* Center-align buttons */
        margin-top: 10px; /* Reduce spacing */
    }

    /* Report Button */
    .report-button {
        width: 100%; /* Full width button for easy access */
        font-size: 14px; /* Adjust font size */
        padding: 12px; /* Increase padding for better tap targets */
        text-align: center; /* Center-align text */
    }

    /* Disclaimer Section */
    .disclaimer {
        font-size: 0.9rem; /* Smaller text */
        padding: 8px; /* Reduce padding */
        text-align: center; /* Center-align content */
    }

    /* Notification Section */
    .notification-toggle {
        width: 40px; /* Smaller size */
        height: 40px;
        margin: 0 auto; /* Center notification button */
    }

    /* Save Latest Button */
    .save-latest-button-container {
        width: 40px; /* Match notification size */
        height: 40px;
        margin: 0 auto; /* Center the save button */
    }

    /* Feedback Messages */
    .feedback-message {
        font-size: 12px; /* Adjust font size */
        padding: 8px; /* Adjust padding */
        text-align: center; /* Center-align messages */
    }
}
