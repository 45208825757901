/* Main container adjustments */
.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: var(--background-color);
    padding: 20px;
    max-width: calc(100% - 16px); /* Account for the scrollbar width */
    box-sizing: border-box; /* Include padding in width calculation */
}

/* Content styling */
.home-content {
    text-align: center;
    background-color: var(--card-background);
    color: var(--text-color);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 50px 100px; /* Even more spacious padding */
    max-width: calc(1600px - 16px); /* Reduce the maximum width by scrollbar size */
    width: calc(90% - 16px); /* Dynamically scale width and account for scrollbar */
    margin: 20px auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

/* Title with gradient text */
.home-title {
    font-size: 2.8rem;
    font-weight: 700;
    color: var(--text-color);
    margin-bottom: 20px;
}

/* Description text */
.home-description {
    font-size: 1.4rem;
    color: var(--text-color-secondary);
    margin-bottom: 40px;
}

/* Button container styling */
.home-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
}

/* Button styling */
.home-button {
    padding: 12px 30px;
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--button-text);
    background: var(--button-background);
    border: none;
    border-radius: 50px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Button hover styling */
.home-button:hover {
    background: var(--hover-background);
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

/* Separator line */
.separator-line {
    width: 100%;
    margin: 30px 0;
    border: 0;
    border-top: 1px solid var(--border-color);
}

/* Recent Games Slider */
.recent-games-slider {
    text-align: center;
    background-color: var(--card-background);
    border-radius: 20px;
    width: 100%; /* Ensure it spans the container width */
    box-sizing: border-box; /* Include padding in width calculation */
}



/* Slick Dots */
.slick-dots {
    bottom: -30px; /* Move dots further below the slider */
    display: flex;
    justify-content: center; /* Center-align dots */
    gap: 10px; /* Add spacing between dots */
}

.slick-dots li button:before {
    font-size: 12px; /* Adjust size of dots */
    color: var(--text-color);
    opacity: 0.5; /* Adjust opacity for inactive dots */
    transition: opacity 0.3s ease; /* Smooth transition for opacity */
}

.slick-dots li.slick-active button:before {
    opacity: 1; /* Make active dot fully opaque */
    color: var(--text-color);
}

/* Slick Arrows */
.slick-prev,
.slick-next {
    width: 45px; /* Size of the arrows */
    height: 45px;
    z-index: 10; /* Ensure arrows are above content */
    background-color: var(--button-background); /* Semi-transparent background */
    border-radius: 50%; /* Make arrows circular */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, background-color 0.3s ease;
    margin: 0 10px; /* Add spacing from the slider */
    position: absolute; /* Ensure arrows are positioned properly */
}

.slick-prev:hover,
.slick-next:hover {
    background-color: var(--hover-background);
}

.slick-prev:before,
.slick-next:before {
    font-size: 18px; /* Adjust arrow icon size */
    color: var(--button-text); /* Ensure arrow icons remain visible */
    opacity: 1; /* Keep arrows fully visible */
    transition: color 0.3s ease; /* Smooth transition for icon color */
}

/* Position Adjustments */
.slick-prev {
    left: -60px; /* Position left arrow with spacing */
}

.slick-next {
    right: -60px; /* Position right arrow with spacing */
}

/* Slider Item Adjustments */
.slider-item {
    margin: 0 15px; /* Add more spacing between items */
    background-color: var(--card-background);
    border-radius: 10px;
    text-align: center;
    flex: 0 0 auto;
    width: calc(100% / 5 - 30px); /* Fit 5 items per slide */
    max-width: 220px; /* Ensure consistent width */
    padding: 15px;
    transition: transform 0.6s ease-in-out, box-shadow 0.6s ease-in-out;
}


/* Slider item hover effect */
.slider-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Game Image Adjustments */
.game-image {
    width: 100%;
    height: auto;
    max-height: 150px; /* Increased height for better visibility */
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
}

/* Game title */
.game-title {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--text-color);
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Game link */
.game-link-home {
    display: inline-block;
    padding: 8px 15px;
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--button-text);
    background: var(--button-background);
    border-radius: 50px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Game link hover */
.game-link-home:hover {
    background: var(--hover-background);
    transform: translateY(-3px);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

/* Falling Items Container */
.falling-items-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    overflow: hidden;
    z-index: 100; /* Ensure it overlays other content */
}

/* Falling Item Styles */
.falling-item {
    position: absolute;
    top: -50px; /* Start slightly above the viewport */
    color: white;
    user-select: none;
    pointer-events: none;
    animation: fall linear infinite;
    font-family: 'Arial', sans-serif; /* Standard font for symbols */
}

/* Specific Separator Line for Recent Games */
.separator-line.recent-games {
    margin-top: 40px; /* Add extra space above the line */
    margin-bottom: 30px; /* Add more space below the line */
}

  
/* Slow down the fall speed */
@keyframes fall {
    0% {
        transform: translateY(-100vh) rotate(0deg);
    }
    100% {
        transform: translateY(100vh) rotate(360deg);
    }
}

@media (max-width: 768px) {
    /* Main container adjustments */
    .home-container {
        padding: 10px; /* Reduce padding for smaller screens */
        max-width: 100%; /* Use full width for smaller devices */
        box-sizing: border-box; /* Include padding in width calculation */
        overflow-x: hidden; /* Prevent horizontal scrolling */
    }

    /* Content styling */
    .home-content {
        padding: 30px 15px; /* Adjust padding */
        max-width: 100%; /* Full width for mobile */
        width: 100%; /* Ensure no horizontal overflow */
        margin: 0 auto; /* Center the content */
        box-sizing: border-box; /* Include padding in width */
    }

    /* Title adjustments */
    .home-title {
        font-size: 2rem; /* Reduce font size for smaller screens */
        margin-bottom: 15px; /* Adjust spacing */
    }

    /* Description text */
    .home-description {
        font-size: 1rem; /* Reduce font size */
        margin-bottom: 30px; /* Adjust spacing */
        text-align: center; /* Ensure description is centered */
    }

    /* Buttons adjustments */
    .home-buttons {
        flex-direction: column; /* Stack buttons vertically */
        gap: 10px; /* Adjust spacing between buttons */
        width: 100%; /* Ensure buttons are responsive */
        align-items: center; /* Center-align buttons */
    }

    .home-button {
        padding: 10px 20px; /* Adjust button padding */
        font-size: 1rem; /* Reduce font size */
        width: auto; /* Prevent buttons from stretching */
    }

    /* Recent Games Slider */
    .recent-games-slider {
        padding: 0 10px; /* Ensure padding fits within mobile screens */
        max-width: 100%; /* Full width for slider */
        box-sizing: border-box; /* Include padding in width */
    }

    .slick-list {
        margin: 0; /* Reset any margin causing misalignment */
        padding: 0; /* Reset padding for clean layout */
    }

    .slick-track {
        margin: 0 !important; /* Remove default margin */
    }

    /* Slider Item Adjustments */
    .slider-item {
        margin: 0 auto; /* Center-align slider items */
        width: 100%; /* Ensure slider items are full width */
        max-width: none; /* Remove max-width constraints */
        box-sizing: border-box; /* Include padding in width */
        padding: 10px; /* Adjust padding for smaller items */
    }

    /* Image Adjustments */
    .game-image {
        width: 100%; /* Fully responsive images */
        max-height: 100px; /* Reduce height for smaller screens */
        object-fit: cover; /* Ensure images fill container */
        border-radius: 8px;
    }

    /* Game Title Adjustments */
    .game-title {
        text-align: center; /* Center-align text */
        font-size: 1rem; /* Adjust font size */
        margin-bottom: 10px; /* Add spacing below titles */
    }

    /* Separator Line */
    .separator-line {
        margin: 20px 0; /* Reduce margin size */
    }

    /* Slick Dots Adjustments */
    .slick-dots {
        gap: 5px; /* Adjust dot spacing */
        bottom: -20px; /* Adjust positioning */
    }

    .slick-dots li button:before {
        font-size: 10px; /* Adjust dot size */
    }

    /* Slick Arrows */
    .slick-prev,
    .slick-next {
        width: 35px; /* Reduce arrow size */
        height: 35px;
    }

    .slick-prev {
        left: 5px; /* Adjust position for smaller screens */
    }

    .slick-next {
        right: 5px; /* Adjust position for smaller screens */
    }

    .slick-prev:before,
    .slick-next:before {
        font-size: 16px; /* Adjust arrow icon size */
    }

    /* Falling Items Container */
    .falling-items-container {
        overflow-x: hidden; /* Ensure no horizontal scrolling caused by animations */
    }

    /* Footer Adjustments (if applicable) */
    .footer {
        text-align: center;
        font-size: 0.9rem;
        color: var(--text-color-secondary);
        margin-top: 20px;
    }
}
