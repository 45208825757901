.verify-email-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    text-align: center;
    padding: 20px;
}

.verify-email-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
    animation: fadeIn 1s ease-in-out;
}

.verify-email-message {
    font-size: 1.2rem;
    padding: 15px 20px;
    border-radius: 8px;
    max-width: 400px;
    animation: fadeIn 0.5s ease-in-out;
}

.verify-email-message.success {
    color: #155724;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
}

.verify-email-message.error {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
}

/* Fade-in animation */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
}
